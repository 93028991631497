#list-editorial {
    @include content-page;

    h1 {
        @include underline-headline($wisteria);
    }

    ul li::before {
        background-color: $wisteria; // purple-colored list bullets
    }

    blockquote {
        border-color: $wisteria;
    }

    .page-description {
        font-size: 1.2rem;
        max-width: 667px;
        width: 100%;
        margin-bottom: 1rem;

        @media (min-width: $tablet) {
            font-size: 1.5rem;
        }
    }

    .updates {
        margin-bottom: 4rem;
        flex-flow: column nowrap;
        align-items: center;

        @media (min-width: $tablet) {
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: initial;
        }
    }

    .card .rich-text * {
        font-size: inherit;
    }

    @media (min-width: $tablet) {
        .card:last-child:nth-child(even)  {
            margin-left: auto;
        }
    }

    .card blockquote { // don't use blockquote border styles on card previews
        border: none;
        padding: 0;
        margin: 0;
    }

    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        @media (min-width: $tablet) {
            justify-content: space-between;
        }
    }

    .meta, .meta p {
        font-size: 1rem;
    }
}