.loader {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.5s;
    z-index: -1;

    img {
        flex: none;
        height: 3rem;
        display: inline-block;
    }

    .loading & {
        opacity: 1;
    }
}