#page {
    @include content-page;
    
    h1 {
        @include underline-headline($pig-pink);
    }
}

.print-only {
    display: none;
}