#contributors {
    .page-title {
        @include underline-headline($pig-pink);
    }

    @include content-page;

    .team,
    .board {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 1rem;
        justify-items: center;
        margin-bottom: 4rem;

        @media (min-width: $tablet) {
            grid-template-columns: 1fr 1fr 1fr;
            justify-items: stretch;
        }

        @media (min-width: $computer) {
            grid-column-gap: 4rem;
        }

        > h2 {
            width: 230px; // align with photos on mobile
            margin: auto;
            grid-column: 1 / -1;
            justify-self: left;

            @media (min-width: $tablet) {
                width: fit-content;
                margin: 0;
            }
        }
    }

    h2 {
        font-size: 1.4rem;
        font-family: $pageFont;
        padding: 0;
    }

    .board > .profile .title {
        display: none; // don't display project roles for board members
    }

    .cards {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;

        @media (min-width: $tablet) {
            flex-flow: row wrap;
            justify-content: space-between;
        }
    }
}