.icon.input > i.clear.times.icon {
    left: auto;
    right: 0;
    cursor: pointer;
    pointer-events: all;
}

.form > .vertical.segment { // stacked form sections
    margin-bottom: 0;
    margin-top: 0;

    &:first-child {
        border-bottom: none;
    }

    &:nth-child(2) {
        border-top: 1px solid #D8D8D8;
    }

    &:last-child {
        margin-bottom: 1em;
    }

    > .twelve.wide.column:not(:nth-of-type(1)) {
        padding-top: 0 !important;
    }
}