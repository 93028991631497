#editorial {
    @include content-page;

    ul li::before {
        background-color: $wisteria; // purple-colored list bullets
    }

    blockquote {
        border-color: $wisteria;
    }

    header {
        @include underline-headline($wisteria);
    }

    h1 {
        // underline headline mixin includes font sizing we need
        @include underline-headline($wisteria);
        border-bottom: 0; // disable redundant border
    }

    .meta {
        @include font-scale;

        p {
            margin-bottom: 0;
            @include font-scale;
        }
    }
}

#editorial,
#list-editorial {
    .meta {
        // separate author and date, doi and pdf by slash
        .author + time,
        .doi + .pdf {
            &:before {
                content: "/ ";
                text-decoration: none;
                display: inline-block; /* required to prevent underline */
                padding-right: 0.25em;
            }
        }

        .author {
            display: inline-block;
        }
    }
}
