#digitized-work {
    padding-top: 100px;
    
    .container:not(.message) {
        @include wide-container;
    }
    
    .meta {
        position: relative;
        padding: 0 1rem;
        border-bottom: 2px solid $seafoam-blue;
        margin-bottom: 1rem;

        @media (min-width: $tablet) {
            padding: 0 2rem;
        }

        // NOTE usage of 1280px breakpoint here to avoid icons getting cut off
        .work-type {
            position: absolute;
            top: -50px;
            left: 10px;

            @media (min-width: $tablet) { left: 25px; }
            @media (min-width: 1280px) { top: 0; }
            
            &[aria-label=article] {
                @media (min-width: 1280px) { left: -80px; }
            }

            &[aria-label=excerpt] {
                @media (min-width: $tablet) { left: 25px; }
                @media (min-width: 1280px) { left: -100px; }
            }
        }

        .header {
            font-size: 1.5rem;
            font-family: $headerFont;
        }

        .work-type[aria-label=article] ~ .header {
            padding-top: 30px;
            @media (min-width: 1280px) { padding-top: 0; }
        }
    }

    .metadata.table {
        table-layout: fixed;
        padding-bottom: 1rem;

        td {
            border: 0;
        }
    
        th {
            padding: 1rem 0;
            vertical-align: top;
            width: 25%;
        }

        th,
        td {
            padding-left: 0 !important;
        }

        tr {
            padding: .5rem 0;
            border: 0;
            box-shadow: none !important;

            @media (min-width: $tablet) {
                padding: 1rem;
            }
        }

        .secondary-title td {
            font-size: 20px;
            font-style: italic;
            font-family: $headerFont;
        }

        .source-link a {
            @include blue-link;
        }
    }

    .pagination {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 1rem;

        .page-controls {
            margin-top: 1rem;
        }

        @media (min-width: $tablet) {
            justify-content: space-between;
            flex-direction: row;

            .page-controls {
                margin-top: 0;
            }
        }
    }

    .results-list .item {
        background-color: initial; // don't "stripe" the results

        &.page {
            margin: 1rem 0;
        }
    }

    .results-list.container { // container for actual page results
        margin-top: 3rem;
        padding: 0 1rem;
    }

    .occurrences {
        margin: 0 auto 2rem;
        color: $warm-grey;
        font-size: 1rem;
    }
}