#histogram {
    height: 8rem;

    .values {
        display: none;
    }

    .visualization {
        width: 100%;
        height: 4rem;
    }

    .min-date,
    .max-date{
        color: $black;
    }

    .max-date {
        float: right;
    }
}
