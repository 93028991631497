#homepage {
    .hero {
        margin-top: 17rem;

        @media (min-width: $tablet) {
            margin-top: 35rem;
        }
    }

    .container {
        text-align: center;

        @media (min-width: 1200px) {
            width: 1140px;
        }
    }
    
    .intro {
        text-align: left;
        font-size: 1.2rem;
        margin-bottom: 3rem;
        
        @media (min-width: $tablet) {
            font-size: 1.5rem;
        }
    }

    .archive-link {
        margin: 0 0 2rem;
        text-decoration: none;
    }

    .asides-collections {
        display: flex;
        flex-direction: column;

        @media (min-width: $computer) {
            flex-direction: row;
            justify-content: center;
            padding: 0;
        }
    }

    .collections {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .description {
            text-align: left;
        }

        .cards {
            margin: 0;
            width: 100%;
            align-content: center;
            align-items: center;
            flex-direction: column;

            @media (min-width: $tablet) {
                flex-direction: row;
                justify-content: flex-end;

                .card:last-of-type {
                    margin-left: auto;
                }
            }
        }

        @media (min-width: $computer) {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 5rem;

            .cards .card {
                margin: 1rem auto;
            }

            .cards {
                flex-direction: column;
            }
        }
    }


    .asides {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 0;

        @media (min-width: $tablet) {
            flex-direction: row;

            .card:first-of-type {
                margin-left: 10px;
            }

            .card:last-of-type {
                margin-left: auto;
            }
        }

        @media (min-width: $computer) {
            flex-direction: column;
        }
    }

    .about-header {
        margin-top: 0;
        font-size: 2rem;

        @media (min-width: $tablet) {
            margin-top: 3rem;
        }

        @media (min-width: $computer) {
            margin-top: 0;
        }
    }
    
    .about-header ~ .description {
        font-size: 1.25rem;
        line-height: normal;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;
        max-width: 100%;

        @media (min-width: $tablet) {
            margin-bottom: 0;
            max-width: 470px;
        }

        @media (min-width: $computer) {
            max-width: 420px;
            padding: 1rem 0;
        }
    }
    
    .collections-link {
        display: inline-block;
        border-bottom: 2px solid $french-blue;
        margin: 2rem 0 4rem;
        text-decoration: none;

        @media (min-width: $tablet) {
            margin: 2rem 0;
        }
        
        .header {
            color: $french-blue;
            font-family: $pageFont;
            font-weight: normal;
            text-transform: uppercase;
        }

        .fermata {
            height: 2rem;
        }
    }
}