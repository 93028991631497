// monochromatics
$white-two: #ffffff;
$white-three: #f7f7f7;
$white: #e9e9e9;
$warm-grey: #737373;
$slate-grey: #5c5c63;
$black: #363636;

// hues
$french-blue: #4661ac;
$seafoam-blue: #57c4c4;
$rosy-pink: #f05b69;
$twilight: #6252a0;

// tints
$azure: #0788fc;
$light-grey-blue: #91d3d3;
$pig-pink: #ed949c;
$wisteria: #9c93c0;

// breakpoints
$mobile: 320px;
$tablet: 735px;
$computer: 1068px;
$largeMonitor: 1440px;
$widescreenMonitor: 1920px;
$nav-breakpoint: 1068px; // point at which we switch to mobile nav

// nav
$nav-height: 55px;
$nav-height-mobile: 50px;

// banner
$banner-height: 230px;
$banner-height-m: 400px;
$banner-height-l: 570px;

// typography
$pageFont              : 'Open Sans', Helvetica, sans-serif;
$headerFont            : 'Gentium Book Basic', Palatino, serif;