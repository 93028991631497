#mobile-nav,
#main-nav {
    img { // icons
        height: 1.25rem;
        width: 1.25rem;
    }

    .search.icon,
    .dropdown.item .icon {
        margin-left: .5rem;
    }

    .dropdown.item .icon {
        transition: all 0.2s ease;
        transform: rotate(90deg);
    }

    .item a {
        text-decoration: none;
    }
}

#mobile-nav {
    top: $nav-height-mobile !important; // have to override SUI's !important
    box-shadow: none;
    border: 0;

    .item {
        text-transform: uppercase !important; // sui...
        padding: 0.75rem !important;
    }

    .ui.dropdown {
        .icon {
            float: none;
        }

        .menu {
            position: relative;
            left: 0;
        }

        .item {
            display: none;
            margin-left: 1rem;
        }

        &.active {
            padding-bottom: 0 !important;

            .item {
                display: block;
            }

            .icon {
                transform: rotate(-90deg);
            }

            > .menu {
                padding-top: 0.5rem;
                height: auto;
            }
        }
    }

    .search.icon {
        display: inline-block;
    }
}

#main-nav {
    padding: 0;
    z-index: 103; // above mobile nav
    transition: margin-top 0.5s;
    border-top: 5px solid $rosy-pink;
    border-bottom: none;
    box-shadow: none;

    &.homepage {
        border-color: $french-blue;
    }

    &.archive {
        border-color: $seafoam-blue;
    }

    &.editorial {
        border-color: $twilight;
    }

    &.error {
        border-color: $black;
    }

    &.hidden {
        margin-top: -100px;
    }

    .brand {
        width: 100%;
        height: 95px;
        display: flex;
        position: absolute;
        justify-content: center;
        background: transparent;
        margin: 0;
        pointer-events: none;

        .item { // PPA logotype
            background: white;
            display: flex;
            height: 95px;
            width: 200px;
            padding: 0;
            opacity: 1;
            transition: opacity 0.2s;
            pointer-events: all;

            &.hidden {
                pointer-events: none;

                @media (max-width: $tablet) {
                    opacity: 0;
                }
            }

            &:hover {
                border: none;
                padding: 0;
            }

            img {
                position: relative;
                width: 150px;
                height: 80px;
                margin: auto;
            }
        }
    }

    .item {
        z-index: 1;
        display: none;
        text-transform: uppercase !important; // override sui

        @media (min-width: $nav-breakpoint) {
            display: flex;
        }

        &.about {
            padding-right: 10px; // avoid placement of triangle over icon
            &.hovered {
                > .icon {
                    transform: rotate(-90deg);
                }

                > .menu {
                    overflow: visible;
                    width: auto;
                    height: auto;
                    top: 100%!important;
                    opacity: 1;
                }

            }
        }

        &.toc,
        &.search {
            display: flex;

            @media (min-width: $nav-breakpoint) {
                display: none;
            }
        }

        &.active,
        &.about.triangle {
            font-weight: bold;
        }

        &.about.triangle::before {
            @include triangle-overlay($rosy-pink, 54px);
        }

        &.editorial.active::before {
            @include triangle-overlay($twilight, 54px);
        }

        &.collections,
        &.archive {
            &.active::before {
                @include triangle-overlay($seafoam-blue, 54px);
            }
        }

        // grey hover/focus triangle overlay for main nav menu items
        &.collections,
        &.archive,
        &.editorial {
            &.focus::before,
            &:focus::before,
            &:hover::before {
                @include triangle-overlay($white, 54px);
                opacity: 1;
            }
        }
    }

    // smaller hover/focus triangle for items in the about menu
    .submenu .item:focus-within::before,
    .about .item:hover::before {
        display: block;
        @include triangle-overlay($white, 40px);
        opacity: 1;
    }

    // PPA logo/brand has smaller triangle shifted to the bottom; no hover
    .brand .item:focus-visible::before {
        top: 54px;
        @include triangle-overlay($white, 42px);
        opacity: 1;
    }

    // remove default outline focus indicator
    a.item:focus,
    .item a:focus {
        outline: none;
    }

    .search .search.icon {
        margin-left: 0;
    }

    .close.icon {
        display: none;
    }

    .dropdown.item:hover .icon {
        transform: rotate(-90deg);
    }
}