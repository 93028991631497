#error {
    @include content-page;
    padding-bottom: 4rem;

    h1 {
        @include underline-headline($black);

        .code {
            font-size: 1.5rem;

            @media (min-width: $tablet) {
                font-size: 2.5rem;
            }
        }
    }

    .content {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
    }

    img {
        flex: 1;
        margin: 0;
        width: 100%; // needed for chrome?
        height: 100%; // needed for chrome?
        min-width: 0; // needed for chrome?

        &.large {
            flex: 2;
        }
    }

    p {
        flex: 2;
        padding-left: 2rem;
        font-size: 1.2rem;

        @media (min-width: $tablet) {
            font-size: 2rem;
            padding-left: 4rem;
        }
    }
}