a:not(.ui):not(.card) {
    text-decoration: underline;
}

a.archive-link { // "view the archive" button
    text-decoration: none;
    display: inline-block;
    padding: 0.8rem;
    background-color: $french-blue;
    border-radius: 3px;

    @media (min-width: $tablet) {
        padding: 1rem;
        margin: 2rem 0;
    }

    .ui.header {
        color: $white-two;
        font-size: 1rem;
        font-weight: bold;
        text-transform: uppercase;
        font-family: $pageFont;

        @media (min-width: $tablet) {
            font-size: 1.2rem;
        }
    }

    .fermata {
        fill: $white-two;
    }
}


#jump-top { // "jump to top" button
    position: fixed;
    right: 1rem;
    top: 75%;
    width: 30px;
    box-shadow: 0 0 6px 0 $french-blue;
    z-index: 10;
    text-decoration: none;

    @media (min-width: $largeMonitor) {
        left: calc(50% + #{$largeMonitor} / 2 - 4rem);
        right: 0;
    }

    &:active {
        background: $french-blue !important; // override sui
    }

    .icon {
        color: $french-blue;

        &::before {
            margin-left: -0.5rem;
        }
    }

    &:hover .icon,
    &:active .icon {
        color: $white-two;
    }
}

// '¶' links to linkable sections in body text
.headerlink {
    color: $french-blue;
    border: none;
    text-decoration: none;
    opacity: 0;
    font-size: 1.2rem;
    transition: opacity 0.2s ease-in-out;
    position: relative;
    top: -0.2rem;

    &:hover, &:focus {
        border: none;
        text-decoration: none;
        color: $french-blue;
    }
}

h2:hover > .headerlink {
    opacity: 1;
}